$(document).ready(function() {
    function pageLoading() {
        var index = layer.load(2, {
            shade: [0.1, '#fff']
        });
        setTimeout(function() {
            layer.close(index);
            $('.index, .move, .friendship').css({
                'opacity': '1'
            });
        }, 1000);
    }
    pageLoading();
    // 声音开关
    var audio = document.getElementById('myMusic');
    $('.btn-music').on('click', function() {
        if (audio.paused) {
            audio.play();
            $(this).find('i').removeClass('shut');
        } else {
            audio.pause();
            $(this).find('i').addClass('shut');
        }
    });
    // 入场动画
    var wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true
    });
    // ie下阻止入场动画
    function isIE() {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
            return
        } else {
            wow.init();
        }
    }
    isIE()
    // 下载链接
    $('.download-box').on('click','a',function() {
    	var className = $(this).attr('class');
        var game_id = 29;
    	$.ajax({
    		type : "get",
    		url: 'https://mapi.qingcigame.com/get_url?game_id='+game_id+'',
    		dataType: 'json',
    		success: function(json) {
    			if (className == 'app-store') {
    				// ios
    				if (json.data.ios_download_url == '') {
    					layer.msg('ios下载暂未开放，敬请期待！<br/>详情请关注官方qq群：666341871', {time:3000});
    				} else {
    					location.href = json.data.ios_down_url
    				}
    			} else {
    				// 安卓
    				if (json.data.android_download_url == '') {
    					layer.msg('安卓下载暂未开放，敬请期待！<br/>详情请关注官方qq群：666341871', {time:3000});
    				} else {
    					location.href = json.data.android_down_url
    				}
    			}
    		}
    	})
    });

    $('.invite-close').click(function() {
        $('.invite-wrap').fadeOut();
    })
    
    // 提交获取账号
    var only = 0;
    $('.submit-btn').click(function() {
        if (only > 0) {
            layer.msg('您已经提交成功，无需重复提交！', {
                time: 1000
            });
        } else {
            var mobile = $('.inp-phone').val();
            var img = $('.inp-img').val();
            var mobileReg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9]|17[0|1|2|3|5|6|7|8|9])\d{8}$/;
            var code = $('.inp-verification').val();
            if (mobile == "") {
                layer.msg('手机号码不能为空', {
                    time: 1000
                });
                return
            }
            if (!mobileReg.test(mobile)) {
                layer.msg('您的手机格式错误！<br/>请输入11位数移动电话号码', {
                    time: 1000
                });
                return
            }
            if (img == "") {
                layer.msg('图形验证码不能为空', {
                    time: 1000
                });
                return
            }
            if (code == "") {
                layer.msg('验证码不能为空', {
                    time: 1000
                });
                return
            }
            if (mobile !== "" && mobileReg.test(mobile) && img !== "" && code !== "") {
                var index = layer.load(2, {
                    shade: [0.1, '#fff']
                });
                // 测试
                // setTimeout(function() {
                // 	layer.close(index);
                // 	$('.account-inf').fadeIn();
                // 	only++
                // },1000);
                $.ajax({
                    url: 'https://api.qcplay.com/activation-code/29',
                    type: 'get',
                    data: '&mobile=' + mobile + '&code=' + code,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function(json) {
                        layer.close(index);
                        if (json.result == true) {
                            only++
                            layer.msg(json.message, {
                                time: 1000
                            });
                            $('.account-inf').fadeIn();
                            $('#account-number').html(json.data.code);
                        } else {
                            layer.msg(json.message, {
                                time: 1000
                            });
                        }
                    }
                });
            };
        }
    });
    // 点击刷新图片验证码
    $('.obtain-img').click(function() {
            var timestamp = Date.parse(new Date());
            $(this).find('img').attr('src', 'https://api.qcplay.com/captcha?channel=activation&rand=' + timestamp);
        })
        // 获取短信验证
    $('.obtain-verification').on('click', 'a', function() {
        var mobile = $('.inp-phone').val();
        var captcha = $('.inp-img').val();
        var mobileReg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9]|17[0|1|2|3|5|6|7|8|9])\d{8}$/;
        if (mobile == "") {
            layer.msg('手机号码不能为空', {
                time: 1000
            });
            return
        }
        if (!mobileReg.test(mobile)) {
            layer.msg('您的手机格式错误！<br/>请输入11位数移动电话号码', {
                time: 1000
            });
            return
        }
        if (captcha == "") {
            layer.msg('图形验证码不能为空', {
                time: 1000
            });
            return
        }
        if (mobileReg.test(mobile) && captcha !== "") {
            var index = layer.load(2, {
                shade: [0.1, '#fff']
            });
            $.ajax({
                url: 'https://api.qcplay.com/sms',
                type: 'POST',
                data: 'mobile=' + mobile + '&channel=activation&captcha=' + captcha,
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    layer.close(index);
                    if (json.result == true) {
                        time();
                        layer.msg(json.message, {
                            time: 1000
                        });
                    } else {
                        layer.msg(json.message, {
                            time: 1000
                        });
                    }
                }
            });
        };
    });
    // 60s倒计时
    var wait = 60;

    function time() {
        if (wait == 0) {
            $('.obtain-verification a').remove();
            $('.obtain-verification').html("<a>获取验证码</a>");
            wait = 60;
        } else {
            $('.obtain-verification a').remove();
            $('.obtain-verification').html("<b>" + wait + "秒</b>");
            wait--;
            setTimeout(function() {
                time()
            }, 1000)
        }
    };
    // 相关分享
    var changeShare = true;
    $('.btn-share').click(function() {
        var _this = $(this);
        if (changeShare) {
            _this.find('i').removeClass('on');
            $('.share-link').fadeIn();
            setTimeout(function() {
                $('.share-link').addClass('show');
                changeShare = false;
            }, 500);
        } else {
            _this.find('i').addClass('on');
            $('.share-link').removeClass('show')
            setTimeout(function() {
                $('.share-link').fadeOut();
                changeShare = true;
            }, 200);
        }
    });
    // 视频
    var Media = document.getElementById('media-play');
    $('.video').click(function() {
            $('.video-wrap').fadeIn();
            $('.btn-music i').addClass('shut');
            Media.play();
            audio.pause();
        })
        // 关闭
    $('.close').click(function() {
            $('.video-wrap').fadeOut();
            $('.btn-music i').removeClass('shut');
            Media.pause();
            audio.play();
        })
        // 视频开始暂停
    var changeMedia = false;
    $('#media-play').click(function() {
            if (changeMedia) {
                Media.play();
                changeMedia = false;
            } else {
                Media.pause();
                changeMedia = true;
            }
        })
        // 隐藏导航
    var changeNan = true;
    $('.left-logo').on('click', '.nav_more', function() {
        if (changeNan == true) {
            $('.nav_more').addClass('change');
            $('.subnav').addClass('on');
            changeNan = false;
        } else {
            $('.nav_more').removeClass('change');
            $('.subnav').removeClass('on');
            changeNan = true;
        }
    });
    $('.news-btn').click(function() {
        layer.msg('新闻资讯详情暂未开放，敬请期待！<br/>详情请关注官方qq群！');
    });
    $('.feature-btn').click(function() {
        layer.msg('游戏特色详情暂未开放，敬请期待！<br/>详情请关注官方qq群！');
    });
    
    // 点击开启激活码预约弹窗$('.activation-code').click(function() {
    //     $('.activation-wrap').fadeIn();
    // });
    
    // 点击关闭激活码预约弹窗
    $('.activation-close').click(function() {
        $('.activation-wrap').fadeOut();
    });
    // 复制功能
    var clipboard = new Clipboard('.account-number');
    clipboard.on('success', function(e) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);
        layer.msg('账号复制成功', {
            time: 1000
        });
        e.clearSelection();
    });
    clipboard.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });
});


// // 清明节祭奠
// var date = {
//     isDuringDate: function (beginDateStr, endDateStr) {
//         var curDate = new Date(),
//             beginDate = new Date(beginDateStr),
//             endDate = new Date(endDateStr);
//         if (curDate >= beginDate && curDate <= endDate) {
//             $('body').css({'-webkit-filter': 'grayscale(1)','-moz-filter': 'grayscale(1)','-mz-filter': 'grayscale(1)','filter': 'grayscale(1)'});
//             return true;
//         }
//         $('body').css({'-webkit-filter': 'grayscale(0)','-moz-filter': 'grayscale(0)','-mz-filter': 'grayscale(0)','filter': 'grayscale(0)'});
//     }
// };
// date.isDuringDate('2020/04/04', '2020/04/05');
